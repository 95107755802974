.backdrop {
    /* width: 100%; */
    /* min-height: 100vh; */
    /* width: attr(data-width);
    height: attr(data-height); */
    height: 100%;
    overflow: auto;
    position: absolute; 
    /* top: 0; */
    /* top: attr(data-top); */
    /* left: 30%; */
    /* left: attr(data-left); */
    bottom: 0;
    background-color: var(--font-primary);
    background-size: cover;
    opacity: 0.6;
    z-index: 1;
}


@media (max-width: 768px) {
    .backdrop {
        left: 0;
    }
}