.container {
  font-size: 14px;
}

.container h5 {
  color: var(--main-bg-color);
  margin-bottom: 5px;
  font-size: 16px;
}

.bold {
  font-weight: 500;
}

.container > div {
  margin: 12px 0px;
}

.container div:first-child p {
  margin: 2px 0;
  color: var(--font-grey);
}

.container > div:first-child span {
  margin: 2px 0;
  color: var(--font-primary);
}

.tag {
  padding: 1px 3px;
  border-radius: 2px;
}

.container .tag.green {
  background-color: var(--main-bg-color);
  color: white;
}

.container .tag.grey {
  background-color: var(--font-disable);
  color: white;
}

.container .tag.yellow {
  background-color: var(--gold-secondary);
  color: white;
}

.table .row {
  display: flex;
  flex-direction: row;
  padding: 2px;
  border-bottom: 1px solid var(--grey-bg);
}

.table .header {
  background-color: var(--main-bg-color);
  color: white;
  border-radius: 4px;
  border-bottom: none;
  text-align: center;
}

.table .row div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table .row > div:first-child {
  flex-basis: 15%;
}

.table .row > div:nth-child(2) {
  flex-basis: 75%;
}

.table .row > div:last-child {
  flex-basis: 15%;
}
.table .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.grey {
  color: var(--font-grey);
}

.nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.nav button {
  background: none;
}
