.container {
    display: flex;
    flex-flow: column;
}

.row {
    display: flex;
    flex-flow: row nowrap;
    padding: 2px;
    border-bottom: 1px solid var(--font-disable);
}

.header {
    background-color: var(--main-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
}

.row > div:first-child {
    flex-basis: 40px;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.row > div:nth-child(2) {
    flex: 1;
    display: flex; 
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
}

.row > div:nth-child(3) {
    flex-basis: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info {
    margin: 2px 0;
}

.info > p:nth-child(2){
    font-size: 14px;
    color: var(--font-grey);
    margin-bottom: 2px;
}

.index {
    font-size: 14px;
    color: var(--font-grey);
}

.row button {
    background-color: white;
}

