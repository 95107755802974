.container {
    padding: 4px;
}
.container > div {
    margin: 10px 0;
}

.container .btn-bar{
    display: flex;
    flex-direction: column;
}

.info .status {
    margin: 4px 0;
}

.info .status .yes {
    color: white;
    background-color: var(--main-bg-color);
    border-radius: 4px;
    padding: 2px 4px;
}

.info .status .no {
    color: var(--font-primary);
    background-color: var(--grey-bg);
    border-radius: 4px;
    padding: 2px 4px;
}

.btn-bar button {
    margin: 4px 0;
}

.info p {
    color: var(--font-grey);
}

.container .title {
    font-size: 18px;
    margin-bottom: 4px;
    color: var(--main-bg-color);
    font-weight: bold;
}

.info span {
    color: var(--font-primary);
}



