.heading {
    font-size: 24px;
    font-weight: 600;
    margin: 35px 25px;
    color: var(--primary-neutral);
}

@media (min-width: 768px) {
    .heading {
        margin: 25px 0;
        font-size: 36px;
    }
}