.container {
    width: 768px;
    max-width: 768px;
    margin: 0 auto;
}

.nort {
    margin: auto; 
    padding: 0 .5em;
    text-align: center;
    max-width: 500px;
}

@media(max-width: 768px) {
    .container {
        width: 100%;
    }
}

h3 {
  margin: 1em;
  color: var(--font-secondary);
}
