.formWrapper {
    margin: 3%;
}

@media(min-width: 768px) {
    .formWrapper {
        width: 80%;
        margin: 3% auto;
        max-width: 1440px;
    }
}