.container {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.container h5 {
    font-size: 24px;
    text-align: center;
    margin: 15px auto 10px;
}

.table {
    max-width: 768px;
    width: 100%;
    padding: 0 10%;
}

.note {
    margin: 10px auto;
    text-align: center;
}


@media (max-width: 768px) {
    .table {
        padding: 0 10%;
    }
}

