
.sidebar {
    max-width: 340px;
    display: flex;
    flex-flow: column nowrap;
    border-right: 1px solid var(--font-disable);
}
@media (max-width: 768px) {
    .sidebar {
        display: none;
    }
}

.sidebar.hide {
    background-color: #E4E8F0;
}

.toggle {
    display: flex;
    /* padding-top: 18px; */
    padding: 18px 5px 0 0;
    justify-content: flex-end;
}

.nav {
    padding: 40px 40px 0px 40px;
    display: block;
}

.nav.hide {
    display: none;
}

.nav li {
    list-style: none;
    font-size: 16px;
    color: var(--primary-neutral);
    line-height: 20px;
    padding-top: 10px; 
}


