.functionBtn {
  list-style: none;
  padding: 12px 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1 1 auto;
  background-color: white;
  margin: 8px 0;
}

.link {
  text-decoration: none;
  color: var(--primary-neutral);
  text-transform: capitalize;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
}

.wrapper span {
  display: flex;
  align-content: center;
  align-content: center;
  align-items: center;
  margin: 0 5px;
}

.icon {
  background-color: var(--main-light-green);
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.icon svg {
  color: var(--main-bg-color);
}

.icon:hover {
  background-color: var(--main-dark-green);
}
.icon:hover svg {
  color: white;
}

@media (min-width: 768px) {
  .functionBtn {
    padding: 12px;
    border-radius: 10px;
    margin: 14px 10px;
  }
}
