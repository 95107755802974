.container {
  padding: 10px;
  display: flex;
  flex-flow: column;
  width: 100%;
}
.container > div {
  margin: 10px 0;
}

.container .btn-bar {
  display: flex;
  flex-direction: row nowrap;
  width: 300px;
  margin: 0 auto;
  justify-content: center;
}

.container .btn-bar > div {
  margin: 5px;
}

.info .status {
  margin: 4px 0;
}

.info .status .yes {
  color: white;
  background-color: var(--main-bg-color);
  border-radius: 4px;
  padding: 2px 4px;
}

.info .status .no,
.info .status .waiting {
  color: var(--font-primary);
  background-color: var(--grey-bg);
  border-radius: 4px;
  padding: 2px 4px;
}

.btn-bar button {
  margin: 4px 0;
}

.info p {
  color: var(--font-grey);
}

.container .title {
  font-size: 18px;
  margin-bottom: 4px;
  color: var(--main-bg-color);
  font-weight: bold;
}

.info span {
  color: var(--font-primary);
}

.nort-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.nort-wrapper > div:last-child {
  margin: 5px;
}

.review-status {
  color: var(--font-grey);
  font-size: 14px;
  text-align: center;
}
