.close-wrapper {
    display: flex;
    justify-content: right
}

.close-wrapper button {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid var(--font-grey);
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


.content-wrapper {
    padding: 5px;
}

.content-wrapper li {
    list-style: none;
}



