
.icon::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;

}

.icon.yes:before {
  background-color: var(--green-success);
  box-shadow: 1px 1px 7px 2px rgba(40,167,69,0.75);
  -webkit-box-shadow: 1px 1px 7px 2px rgba(40,167,69,0.75);
  -moz-box-shadow: 1px 1px 7px 2px rgba(40,167,69,0.75);
}

.icon.no:before {
  background-color: var(--font-disable);
}
