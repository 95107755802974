.switcher,
.search {
    max-width: 280px;
    margin: 0 auto;
}

.search {
    margin: 5px auto 16px;
}
.search > p {
    color: var(--main-dark-green); 
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
}