.chevron {
    width: 10px;
    height: 10px;
    position: relative;
    border-style: solid;
    border-width: 3px 3px 0 0;
    transform: rotate(135deg);
    transition: .4s ease; 
    border-color: #303A4F;
    cursor: pointer;
}

.active {
    transform: rotate(-45deg);
}


