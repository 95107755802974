.select {
  position: relative;
}

.selectLabel {
  color: var(--main-dark-green);
  font-weight: 400;
  font-size: 16px;
  margin: 15px 0;
  display: block;
  text-transform: capitalize;
}

.selectWrapper {
  position: relative;
}

.selectNative,
.selectCustom {
  position: relative;
  width: 100%;
  height: 36px;
}

.selectCustom {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

@media (hover: hover) {
  .selectCustom {
    display: block;
  }

  .selectNative:focus + .selectCustom {
    display: none;
  }
}

.selectNative,
.selectCustom-trigger {
  background-color: white;
  font-size: 16px;
  color: var(--primary-neutral);
  border: 1px solid #30be75;
  border-radius: 5px;
}

.selectCustom-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 16px;
}

.selectCustom-trigger:after {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url(../../../assets/svg/form-arrow-down.svg);
  position: absolute;
  left: calc(100% - 24px - 16px);
}

.selectCustom-trigger:hover {
  border: 2px solid orange;
}

.selectNative {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 10px;
  background-image: url(../../../assets/svg/form-arrow-down.svg);
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: calc(100% - 10px);
}

.customOption-wrapper {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  border: 1px solid #e4e8f0;
  background-color: white;
  border-radius: 5px;
  /* padding: 0px 16px; */
  text-transform: capitalize;
  z-index: 1;
  display: none;
}

.selectCustom.isActive .customOption-wrapper {
  display: block;
  z-index: 10;
  /* max-height: 400px; */
  max-height: 200px;

  overflow-y: scroll;
}

.selectCustomOption {
  position: relative;
  padding: 6px 16px;
}

.selectCustomOption:hover,
.selectCustomOption.isHover {
  background-color: #30be75;
  color: white;
  cursor: pointer;
}

.selectCustomOption.isActive:after {
  content: "✓";
  position: absolute;
  right: 16px;
}
