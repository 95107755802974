.container {
    /* margin: 10px; */
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.container .note p {
    /* padding: 0.5em; */
    width: 200px;
    text-align: center;
}

.container > div {
    margin: 5px;
    flex-basis: 100px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* padding-bottom: 4px; */
}

.container > div img {
    width: 100px;
    height: 100px;
}

.container > div input {
    text-align: center;
    border: 1px solid var(--font-grey);
    margin: 6px;
}

.score {
    text-align: center;
}

.tag {
    font-weight: bold;
}

.score .tag.red {
    color: var(--red-error);
}

.score .tag.yellow {
    color: var(--yellow-info);
}

.score .tag.blue {
    color: var(--blue-info);
}

.score .tag.green {
    color: var(--green-success);
}