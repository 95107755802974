.wrapper {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 5rem;
  flex: 2 1 auto; 
}

.wrapper > div {
  /* padding: 0 11.875rem; */
  padding: 0 5em; 

  flex: 1;
}

@media (max-width: 992px) {
  .wrapper {
    border-radius: none;
    padding: 3% 0;
  }
  .wrapper > div {
      padding: 0 17rem;
  }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 0;
    }
    .wrapper > div {
        padding: 0 25%;
    }
}

@media (max-width: 375px ) {
    .wrapper > div {
        padding: 0 15%;
    }
}
