.page-choice-panel {
    margin: 10px 10px 0;
}

.page-choice-panel form {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.page-choice-panel form > div {
    padding: 0 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-choice-panel form input {
    margin: 0;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    position: relative;
    top: 1px;
    transition: all 4s linear ease;

}

.page-choice-panel form label {
    margin-left: 4px;
}

.page-choice-panel form input:checked {
    border: 4px solid var(--main-bg-color);
}
