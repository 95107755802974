.burgerIcon {
    width: 18px;
    height: 18px;
    position: relative; 
    cursor: pointer;
}

@media (min-width: 769px) {
    .burgerIcon {
        visibility: hidden;
    }
}

.burgerIcon span {
    width: 100%;
    height: 2px;
    background-color: white;
    position: absolute;
    transition: all .3s ease;
    border-radius: 5px;
}

.burgerIcon span:first-child {
    top: 0;
}

.burgerIcon span:last-child {
    bottom: 0;
}

.burgerIcon.active span:first-child {
    top: 9px;
    transform: rotate(45deg); 
}

.burgerIcon.active span:last-child {
    top: 9px;
    transform: rotate(135deg); 
}

.burgerIcon.active span:nth-child(2){
    display: none;
}

.burgerIcon:hover span {
    transform: translate(2px,-2px);
}
