.mobileNav {
    display: flex;
    position: absolute;
    /* top: calc(25px + 6%vh); */
    color: red;
    width: 100vw;
    background-color: white;
    height: calc(100vh - (6% + 25px));
    padding-top: 80px;
    justify-content: center;
    align-content: center;
    z-index: 10;
}

.mobileNav.hide {
    display: none;
}

.mobileNav span {
    color: black;
}

.mobileNav li {
    list-style: none;
    margin-bottom: 14px;
}

/* .listmenu {
}

.mobileNav li {
    list-style: none;
    margin-bottom: 14px;
}

.wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.title {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.title span {
    margin: 0 5px 0 12px;
    text-align: right;
}

.wrapper span:last-child {
    display: flex;
    align-items: center;
}

.active {
    color: var(--main-bg-color);
}

.submenu li {
    margin-left: 40px;
    margin-top: 10px;
}

.submenu li a {
    color: black;
}

@media(min-width: 768px) {
    .mobileNav {
        display: none;
    }
} */

