.checkbox {
    appearance: none;
    border: 1px solid var(--main-bg-color);
    display: inline-block;
    position: relative;
    padding: 0.4em;
    border-radius: 0.2em;
    width: 20px;
    height: 20px;
    margin-right: 0.2em;
}

.checkbox:hover {
    border: 1px solid #28A745 ;
}

.checkbox:checked {
    background-color: var(--main-bg-color);
}

.checkbox:checked::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    width: 12px ;
    height: 6px ;
    border: 2px solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg) ;
}

.author {
    margin: 0 1em 0 0.1em;
    border-radius: 3px;
    padding: 2px 4px;
    color: white;
    text-align: center;
    background-color: var(--main-bg-color);
    font-size: 12px;
}


.green {
    background-color: #28A745;
}

.red {
    background-color: #EA1010;
}

.yellow {
    background-color: #FFC107;
}
