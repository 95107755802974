.profile-wrapper {
    display: flex;
    flex-flow: column;
    padding: 3em 2em 0 ;
    font-size: 12px;
}

@media (min-width: 768px) {
    .profile-wrapper {
        font-size: 16px;
        padding: 5em 10%;
    }
}

.row {
    display: flex; 
    flex-flow: row nowrap;
    padding: 1em 0;
    border-bottom: 1px solid var(--font-disable) ;
}

.row > div {
    flex: 1 1 auto;
    padding: 0.5em 0 ;
}

.row .photo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-color: blue;
}

.photo img {
    width: 100%;
    height: 100%;

}
.row span {
    font-weight: bold;
    margin-right: 0.5em;
}


