.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  max-width: 142.5rem;
  margin: auto;
  border-radius: 10px;
}

.wrapper div {
  flex: 1 1 50%;
}

.wrapper img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.wrapper .bg {
  background-color: yellowgreen;
  background-image: url(../../assets/images/mcp-main.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0 10px 10px 0;
}

@media (max-width: 992px) {
  .wrapper {
    flex-direction: column-reverse;
    border-radius: none;
  }
  .wrapper .bg {
    border-radius: 0px;
    flex: 1 1 15rem;  
  }
}


