.no {
    color: var(--font-grey)
}

.yes {
    color: var(--green-success)
}

.error {
    color: var(--red-error);
}

