.container {
  margin: 10px;
  padding: 10px;
}

.container > div {
  margin: 10px 0;
}

.container h6 {
  font-size: 14px;
  font-size: bold;
}

.container .heading {
  font-weight: bold;
  margin-bottom: 4px;
  color: var(--main-bg-color);
}

.name-input {
  display: flex;
  flex-direction: column;
}

.name-input div:first-child {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.name-input label {
    padding-right: 4px
}

.name-input input {
  padding: 4px;
  border-radius: 4px;
  flex: 1 1 auto;
}
.name-input div {
  margin: 3px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.members ul {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  padding: 3px;
  border-radius: 3px;
  list-style: none;
}

.members ul > li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  margin: 2px 0;
  border: 1px solid var(--grey-bg);
  color: var(--font-grey);
}

.members li p:first-child {
  color: var(--font-primary);
}

.members ul > li div {
  flex: 1 1 85%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px;
}

.members li div:last-child {
  flex-basis: 15%;
  flex: 1;
}

.members button {
  background: none;
}

.members p {
  margin: 1px 0;
}

.result-nort {
    text-align: center;
}

.note {
  color: var(--font-grey);
}

@media (max-width: 576px) {
  .container {
    font-size: 12px;
  }
}
