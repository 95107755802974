.wrapper {
    margin: 16px auto;
    text-align: left;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.wrapper p {
    margin: 8px 0;
}
