.score-wrapper {
    border: 3px solid red;
    padding: 8px;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    text-align: center;
    line-height: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.worst {
    border: 3px solid var(--grey-bg);
}

.bad {
    border: 3px solid var(--accent-red);
}

.mediocre {
    border: 3px solid var(--gold-secondary);
}

.good {
    border: 3px solid var(--main-bg-color);
}

.excellent {
    border: 3px solid var(--green-success);
    color: var(--green-success);
}