.wrapper {
    margin: 1em auto;
    display: flex;
    flex-flow: column;
    padding: 5px;
}

.wrapper div:first-child {
    margin: 1em auto;
}

.wrapper h3 {
    text-align: center;
    margin: .5rem;
}

.form-input {
    display: flex;
    flex-flow: column;
    margin: 10px 0;
}

.form-input input {
    margin: 8px 0;
    height: 20px;
    padding-left: 8px;
}

.wrapper button {
    background-color: var(--green-success);
    color: white;
    padding: 6px 4px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 4px;
}

.nort-section {
    margin: 1em auto;
    max-width: 350px;
    text-align: center;
}