.button {
    padding: 0.3em 0.6em;
    border-radius: 0.3em;
    color: white;
    margin: 0 0.3em;
    font-size: 12px;
}

.button.green {
    background-color: #28A745;
}

.button.red {
    background-color: #EA1010;
}

.button.yellow {
    background-color: #FFC107;
}

.button.blue {
    background-color: #007BFF;
}

.button.grey {
    background-color: var(--grey-bg);
    color: var(--font-primary)
}

.button button:disabled {
    background-color: var(--grey-bg);
}