.container {
    position: fixed;
    /* top: 4rem; */
    top: 50%;
    left: 50%;
    /* transform: translateX(-50%); */
    transform: translate(-50%, -50%);
    z-index: 10;
    overflow: initial; 
}

@media (max-width: 320px) {
    .container {
        width: 90%;
        margin: auto;
    }
}

@media (min-width: 768px) {
    .container {
        position: absolute;
    }
}

.card {
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 36px;
    min-width: 280px;
    height: 70vh;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .card {
        padding: 10px 12px;
        height: 70vh;
        overflow-y: auto;
    }

}
