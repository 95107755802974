.wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.row {
    display: flex;
    flex-grow: row nowrap;
    border-bottom: 1px solid var(--font-disable);
}

.header {
    background-color: var(--main-bg-color);
    color: white;
    border-radius: 5px;
    border: none;
}

.row > div {
    padding: 4px;
}

.col1 {
    flex-basis: 65%; 
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.col1 p {
    margin: 2px 0;
}

.col1 p:nth-child(2) {
    font-size: 14px;
    color: var(--font-grey);
}

.sm-col {
    flex-basis: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}