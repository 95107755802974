.profilePanel {
    /* position: absolute; */
    position: fixed;
    top: 9.125rem;
    /* right: 2rem; */
    /* left: calc(60% - 100px); */
    left: 70%;
    display: flex;
    flex-flow: column;
    background-color: var(--main-light-green);
    color: black;
    font-size: 14px;
    padding: 1rem;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    border-bottom: 1px solid var(--main-bg-color);
    z-index: 10;
}

.hide {
    display: none;
}

.profilePanel.alignLeft {
    align-items: flex-start;
}

.profileMenu {
    padding: .5rem;
    cursor: pointer;
}

.profilePanel svg {
    color: var(--main-bg-color);
    margin: 0 5px;
}

.profileMenu button {
    border: none;
    background: none;
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
}

@media (max-width: 576px) {
    .profilePanel {
        top: 6rem;
        min-width: 200px;
        left: calc(60% - 100px);
    }
}

@media (min-width: 2560px) {
    .profilePanel {
        top: 6rem;
        min-width: 200px;
        left: 65%;
    }
}