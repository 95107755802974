.close {
    margin: 4px 0 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    padding: 0 1em;
}

.close button {
    background-color: white;
}