.wrapper {
    margin: 3px 0;
}

.accordion {
    background-color: var(--main-bg-color);
    color: white;
    width: 100%;
    text-align: left;
    padding: 12px 16px;
    border: none; 
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.icon {
    background-color: var(--main-light-green);
    width: 24px;
    height: 24px;
    border-radius: 3px;
}

.icon svg {
    color: var(--main-bg-color)
}

.panel {
    padding: 12px 16px;
}