.container {
    font-size: 14px;
}

.container h5 {
    color: var(--main-bg-color);
    margin-bottom: 5px;
    font-size: 16px;
}

.container > div {
    margin: 12px 0px;
}

.container div:first-child p {
    margin: 2px 0;
    color: var(--font-grey);
}

.container div:first-child span {
    margin: 2px 0;
    color: var(--font-primary);
}

.tag {
    padding: 1px 3px;
    border-radius: 2px;
}

.container .tag.green {
    background-color: var(--main-bg-color);
    color: white;
}

.container .tag.grey {
    background-color: var(--font-disable);
    color: white;
}

.container .tag.yellow {
    background-color: var(--gold-secondary);
    color: white;
}

.admin-panel {
    display: flex; 
    flex-grow: row nowrap;
    justify-content: center;
}