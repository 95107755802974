.container {
  padding: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-flow: column;
  display: flex;
}

.input {
  margin: 10px 0;
}

.input label {
  font-size: 14px;
  color: var(--font-grey);
}

.input textarea {
  margin: 8px;
  padding: 6px;
  border: 1px solid var(--main-bg-color);
}

.btn {
  display: flex;
  justify-content: center;
}

.btn button {
  padding: 10px 16px;
  border-radius: 8px;
  background-color: var(--main-bg-color);
  color: white;
}

.nort {
  margin: 8px;
  font-size: 14px;
  color: var(--font-grey);
}
