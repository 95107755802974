.profile > div {
    margin: 5px;
}

.profile > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
}



.avatar img {
    width: 50px;
    height: 50px;
}

.chevron {
    cursor: pointer;
}


@media (max-width: 768px ) {
    .profile.profileName {
        display: none;
    }    
}