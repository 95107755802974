.form {
    padding: 5% 3%;
    background-color: #F6F6F6;
    border-radius: 9px;
    font-size: 0.875rem;
    width: 100%;
}

.standardForm .field-wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 7px;
    align-content: flex-start;
    
}

.form .selectionForm {
    margin: 20px 0 
}

.selectionForm {
    display: flex;
    flex-flow: column nowrap;
    align-content: flex-start;
    text-align: left;
}

.form label {
    color: var(--main-dark-green);
    font-size: 16px;
    margin-bottom: 15px;
}

.form .selectionForm label {
    align-self: left;
}

.standardForm label {
    flex-basis: 20%;
    align-self: left;
}

.standardForm input {
    flex: 2 1 90%;
    min-height: 2rem;
    border-color: var(--main-bg-color);
}

.standardForm input::placeholder {
    padding-left: 10px;
}

.form button {
    border: none; 
    background-color: none;
    border-radius: 6px;
    padding: 8px 10px; 
    margin: 0 7px;
    font-size: 1rem;
}

.form .action-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 20px 10% 0;
}

.create-btn {
    background-color: var(--main-bg-color);
    color: white;
}

.cancel-btn {
    background-color: white;
}

.modal-title {
    padding-left: 3%;
}


