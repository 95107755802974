.info-container {
    margin: 10px 0;
    font-size: 18px;
}

.info-container .title {
    font-weight: bold;
    color: var(--font-primary);
}

.info-container p {
    color: var(--font-grey);
    font-weight: bold;
}

.info-container span {
    color: var(--font-primary);
    font-weight: normal;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}