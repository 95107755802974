.mobileNav li {
    list-style: none;
    margin-bottom: 14px;
}

.wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.title {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.title span {
    margin: 0 5px 0 12px;
    text-align: right;
}

.wrapper span:last-child {
    display: flex;
    align-items: center;
}

.active {
    color: var(--main-bg-color);
}

.submenu li {
    margin-left: 40px;
    margin-top: 10px;
}

.submenu li a {
    color: black;
}

.submenu.hide {
    display: none;
}

@media(min-width: 768px) {
    .mobileNav {
        display: none;
    }
}

