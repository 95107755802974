.card {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
}

.card-wrapper {
    width: 100%;
}

.card > div {
    margin: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.card img {
    width: 100%;
}

.card li {
    color: var(--font-grey);
    text-align: center;
    margin-bottom: 5px;
}

.closeBtn {
    background-color: var(--font-grey);
    width: 16px;
    height: 16px;
    padding: 1px 0 0;
    border-radius: 50%;
}


.error {
    color: var(--red-error);
    padding: 2px;
}