.form {
    margin: 20px 0 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 4px;
    border-radius: 5px;
}

.form div {
    display: flex;
    flex-flow: column;
    margin: 4px auto;
}

.form input {
    height: 20px;
    padding-left: 4px;
    margin: 4px 0;
}

.error-message {
    font-size: 12px;
    color: var(--red-error);
    text-align: center;
}