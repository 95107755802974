.nort {
  text-align: center;
  margin-top: 0.8em;
}

.error {
  color: #ea1010;
}

.info {
  color: #007bff;
}

.warning {
  color: #ffc107;
}

.success {
  color: #28a745;
}

.ok {
  color: #28a745;
}
