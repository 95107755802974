.rating-bar {
  text-align: center;
  color: var(--font-grey);
}

.rating-bar span {
  color: var(--font-primary);
  font-weight: bold;
}

.rating-bar .red {
  color: var(--red-error);
}

.rating-bar .yellow {
  color: var(--yellow-info);
}

.rating-bar .blue {
  color: var(--blue-info);
}

.rating-bar .green {
    color: var(--green-success);
}
