.panel {
  display: flex;
  justify-content: flex-end;
}
.panel button {
  background: none;
  color: var(--font-grey);
}

.table {
  margin: 10px 0;
}

@media (max-width: 576px) {
  .table {
    font-size: 12px;
  }
}

.label {
    color: var(--font-grey);
}

.row {
  display: flex;
  flex-flow: row nowrap;
  padding: 2px;
}

.row > div {
  display: flex;
  justify-content: center;
}

.row div:first-child {
  flex-basis: 5%;
}

.row div:last-child {
  flex-basis: 35%;
}

.row div:nth-child(2) {
  flex: 1 1 60%;
}

.header {
    background-color: var(--main-bg-color);
    color: white;
    border-radius: 4px 4px 0px 0px;
}
