* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.app {
    height: 100%
}

input {
    border-radius: 6px; 
    border: 1px solid var(--main-bg-color);
}

button:active {
   text-decoration: none;
}

.panelHeading {
    font-size: 16px;
    color: var(--primary-neutral);
    font-weight: 600;
}

@media (min-width) {
    .panelHeading {
        font-size: 24px;
    }
}