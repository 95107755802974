.container {
    display: flex;
    flex-flow: column;
    margin: 8px 0;
}

.row {
    display: flex;
    flex-flow: row nowrap;
    padding: 2px;
    border-bottom: 1px solid var(--font-disable);
}

.header {
    background-color: var(--main-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
}

.row > div:first-child {
    flex-basis: 40px;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.row > div:nth-child(2) {
    flex: 1;
    display: flex; 
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
}

.row > div:nth-child(3) {
    flex-basis: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info p {
    font-size: 14px;
    color: var(--font-primary);
    margin: 2px 0 ;
}

.info span {
    color: var(--font-grey)
}

.info p:first-child {
    font-size: 16px;
}

.index {
    font-size: 14px;
    color: var(--font-grey);
}

.row button {
    background-color: white;
}

.score {
    font-weight: bold
}
  
.score.red {
color: var(--red-error);
}

.score.yellow {
color: var(--yellow-info);
}

.score.blue {
color: var(--blue-info);
}

.score.green {
color: var(--green-success);
}

.score.none {
    font-weight: normal;
    color: var(--font-primary);
}

.info .dish-name {
    color: var(--main-dark-green);
    font-weight: 400;
}

.center {
    text-align: center;
}