.panelWrapper {
    /* background-color: white; */
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 23px;
    padding: 20px;
}

@media (min-width: 768px) {
    .panelWrapper {
        border-radius: 40px;
        padding: 36px 24px;
    }
}
