.container {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    margin: 8px 0;
}

.row .img-container {
    position: relative;
    width: 100%;

}

.row img {
    width: 100%;
    aspect-ratio: 1;
}

.row > div {
    padding: 4px;
}

.row > div:nth-child(1) {
    flex-basis: 35%;
}
.row > div:nth-child(2) {
    flex-basis: 65%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.row > div:nth-child(2) div {
    margin-bottom: 4px;
}

.item-name {
    word-break: break-all;
}

.running-note span {
    padding: 2px;
    color: white;
    border-radius: 2px;
    background-color: var(--grey-bg);
}

.complete-note span {
    padding: 2px;
    color: white;
    border-radius: 2px;
    background-color: var(--green-success);
}

.error-note span {
    padding: 2px;
    color: white;
    border-radius: 2px;
    background-color: var(--red-error);
}

.close-wrapper {
    text-align: center;
    font-size: 12px;
}

.close-wrapper p {
    max-width: 250px;
    margin: auto;
}

.close-wrapper button {
    margin: 4px;
    padding: 3px 4px;
    color: white;
    border-radius: 4px;
    background-color: var(--green-success);
}

.close-wrapper button:disabled {
    background-color: var(--grey-bg);
}

.loading-wrapper {
    font-size: 12px;
    text-align: center;
}