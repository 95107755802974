.nortification-container {
    display: flex;
    flex-flow: column nowrap;
    align-content: flex-end;
    align-items: flex-end;
    padding: .5em 0;
}

.nor-unit {
    width: fit-content;
    border: 1px solid #e4570a;
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px;
    padding: 1px 5px;
    /* background-color: #e4570a; */
    margin: 2px 0;
    align-content: flex-end;
}

.nor-hidden {
    display: none;
}

.nortification-container span {
    margin: 3px;
}

.closeBtn {
    font-size: 10px;
    color: #e4570a;
}

.err-msg {
    font-size: 12px;
    color: var(--primary-neutral);
}



