.accordionWrapper {
  background-color: white;
  border: 1px solid #e4e8f0;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  overflow: hidden;
  padding: 5px;
  /* css to help overflow y of MonAn page */
  overflow-y: auto;
}

@media (min-width: 768px) {
  .accordionWrapper {
    padding: 48px 36px;
  }
}


