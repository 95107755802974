.not-found {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column nowrap; 
    flex: 1 1 80%;;
    align-items: center;
    text-align: center;
}

.not-found h3 {
    font-size: 5em;
    color: var(--gold-secondary);
}

.not-found p {
    font-size: 1.5em;
    margin: 5px 0; 
}

.bold {
    font-weight: bold;
}




