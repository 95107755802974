img {
    width: 100%;
    aspect-ratio: auto;
    max-width: 1024px;
}

.info {
    font-size: 11px;
    word-break: break-all;
}

.info p {
    margin: 2px 0;
}

.info .title {
    color: var(--font-grey);
}

@media (min-width: 768px) {
    .info {
        font-size: 13px;
    }
}

