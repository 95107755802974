.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--grey-bg);
    padding: 3px;
    border-radius: 4px;
}

.tool-bar {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

}

.tool-bar p {
    color: var(--font-grey);
}

.count {
    color: var(--main-dark-green);
    font-weight: bold;
}

.tool-bar > div {
    margin: 4px;
}

.tool-bar select {
    padding: 2px;
    border: 1px solid var(--font-grey);
    border-radius: 2px;
}

.submit-response {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.submit-response .ok {
    color: var(--green-success);
}

.submit-response .error {
    color: var(--red-error);
}