.wrapper {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.grey {
  background-color: var(--grey-bg);
}

.green {
  background-color: var(--main-bg-color);
}
