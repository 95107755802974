.text-field {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid var(--main-bg-color);
    border-radius: 4px; 
    padding: 4px 4px;
    align-items: center;
    align-content: center;
    margin: 8px 0;
}

.text-field input {
    flex: 2;
    border: none;
    margin: 0 8px;
    padding-left: 2px;
    border-radius: 0;
    height: 20px;
    outline: none; /*to remove border when typing*/
}

.text-field input:visited {
    border: none; 
}

.text-field button {
    display: flex;
}

.text-field button::after {
    content: '';
    position: relative;
    width: 100%;
    height: 20px;
    border-right: 2px solid var(--main-bg-color); 
    left: 6px;
    top: 2px;
}

.btn-panel {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
