.wrapper {
  padding: 2%;
}

.search-wrapper {
  border-radius: 4px;
  padding: 4px 8px;
  background-color: var(--grey-bg);
  overflow-y: auto;
}

.btn-nav {
  margin: 8px 0px;
  display: flex;
  justify-content: center;
}

.verdict > div {
  margin: 10px 0;
}

.verdict p {
  margin: 4px 0;
}

.score-stat-table {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  border-radius: 8px;
  border: 3px solid var(--main-light-green);
}

.row {
  display: flex;
  flex-flow: row nowrap;
  padding: 2px 4px;
}

.row > div:first-child,
.row > div:nth-child(2) {
  flex-basis: 40%;
}

.row > div:last-child {
  flex-basis: 20%;
}

.row > div {
  padding: 4px;
}

.row-label {
  background-color: var(--main-light-green);
  border-radius: 8px 8px 0 0;
}

.label {
  color: var(--primary-neutral);
  font-weight: bold;
}

.label.blue {
  color: var(--blue-info);
}

.important {
  color: var(--blue-info);
  font-weight: 400;
}

.padL {
  padding-left: 1em;
}

.warning {
  color: var(--font-grey);
  font-size: 14px;
}

.center {
  text-align: center;
}

.label {
  font: var(--font-grey);
  font-size: 1.15em;
  font-weight: normal;
}

.list-row {
  margin-bottom: 8px;
  font-weight: 500;
}

.list-row li {
  font-weight: normal;
  margin-left: 1em;
  list-style: none;
  margin: 4px 0 4px 1.5em;
}

.value {
  font-weight: bold;
}

.modal-nav {
  display: flex;
  justify-content: center;
}

.close-modal {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: var(--font-grey);
  color: white;
}

.nort-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.nort-bar p {
  color: var(--blue-info);
}
