.nortification-container {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    align-items: center; 
}

.nor-unit {
    width: fit-content;
    border: 1px solid #e4570a;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 1px 5px;
    /* background-color: #e4570a; */
    margin: 2px 0;
    align-content: flex-end;
    margin: 10px auto;
}

.nor-unit.ok-msg {
    border: 1px solid var(--main-bg-color);
}

.nor-hidden {
    display: none;
}

.nortification-container span {
    margin: 3px;
}

.closeBtn {
    font-size: 10px;
    color: #e4570a;
}

.closeBtn.ok-msg {
    color: var(--main-bg-color);
}

.closeBtn.err-msg {
    color: #e4570a;
}

.err-msg {
    font-size: 12px;
    color: var(--primary-neutral);
}

.ok-msg {
    font-size: 12px;
    color: var(--primary-neutral);
    border: var(----main-bg-color);
}



