.header {
    background-color: var(--main-bg-color);
    color: white; 
    font-size: 16px;
}

.headerNav {
    display: flex;
    padding: 2.5rem 11.875rem;
    max-width: 142.5rem;
    margin: auto;
    align-items: center;
}

.logged-in {
    justify-content: space-between;
}

.logged-out {
    justify-content: center;
}

.headerNav.logged-in > div {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.header .profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.header a {
    list-style: none;
    color: white;
    text-decoration: none;
    /* margin: 3px; */
}

@media (max-width: 576px) {
    .headerNav {
        padding: 3% 10%;
        /* width: 80%; */
    }
}