.profile {
    padding: 20px;
    color: var(--primary-neutral);
}

.profile h5 {
    color: var(--main-dark-green) ;
    margin: 15px 0 ;
}

.profile p {
    padding: 3px;
}

.profile span {
    display: inline-block;
    font-weight: bold;
    /* min-width: 4em ; */
    color: var(--font-primary);
}