.flexTable {
    display: flex;
    flex-flow: column; 
    justify-content: center;
    align-content: center;
    margin: 3%;
    font-size: 14px;
}

@media(min-width: 768px) {
    .flexTable {
        width: 80%;
        margin: 3% auto;
        max-width: 1440px;
    }
}

.theader {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px ;
    background-color: var(--main-light-green);
    color: var(--main-dark-green);
}

.theader div {
    /* flex: 1 1 calc(100%/3); */
    justify-content: flex-start;
}


.tableRow {
    display: flex;
    flex-flow: row nowrap; 
    border-bottom: 1px solid #E4E8F0;
    padding: 5px;
}

.tableRow div {
    /* flex: 1 1 50%; */
    justify-content: flex-start;
}

.tableRow > div {
    padding: 0 2px;
}

.button-bar {
    text-align: center;
}

.tableRow .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.theader div:first-child, 
.tableRow div:first-child {
    flex-basis: 50px;
}

.theader div:nth-child(2), 
.tableRow div:nth-child(2),
.theader div:nth-child(3), 
.tableRow div:nth-child(3) {
    flex: 1;
    /* padding-right: 5px ; */
}

.theader div:last-child,
.tableRow div:last-child {
    flex-basis: 200px;
    flex: 1;
}

.button-row.center {
    justify-content: center;
}