* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}


body {
  margin: 0;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
  /* width: 100%; */
};

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: #30BE75; 
  --main-dark-green: #008D49;
  --main-light-green: #E6F5ED; 
  --font-primary: #0C0D15; 
  --font-secondary: #171822; 
  --font-disable: #cbcbcb;
  --font-grey: #606060;
  --accent-red: #CE235D;
  --dark-red: #FF768F;
  --primary-neutral: #303a4f;
  --gold-secondary: #f8BD26; 
  --gold-dark: #C18D00; 
  --gold-light: #F8F5E6;
  --grey-bg: #E5E5E5;
  --green-success: #28A745;
  --red-error: #EA1010;
  --blue-info: #007BFF; 
  --yellow-info: #FFC107;
}

.App {
  height: 100%; 
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}

/* .App>div {
  height: 100%;
} */
