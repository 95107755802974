.HrDashBoard {
    background-color: var(--grey-bg);
    flex: 1 1 auto;
}


@media (min-width: 768px) {
    .HrDashBoard {
        padding: 25px 10%;
    }
}