.mainDashBoard {
    display: flex;
    flex-flow: column; 
    flex: 1 1 auto;
    align-content: center;
    justify-content: center;
    padding: 15%;
    text-align: center;
    background-color: var(--grey-bg);
}

.mainDashBoard h3 {
    margin: 15px 0px;
}

.mainDashBoard p {
    color: var(--main-bg-color);
}