.menu {
    font-size: 16px;
    padding: 0 16px 10px;
}

.menu a {
    text-decoration: none;
    color: inherit; 
    cursor: pointer;  
}

.item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.chevron {
    display: flex;
    align-items: center;
}


.submenu li {
    padding: 5px 40px;
}

.submenu a {
    color: var(--font-grey);
    font-size: 14px;
}

.submenu li:last-child {
    padding-bottom: 0;
}

.hide {
    display: none ;
}

.show {
    display: block;
}

.wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}


.menu .nav:hover, 
.menu .nav-active{
    color: var(--main-bg-color);
}

.wrapper span:first-child {
    display: flex;
    align-items: center;
}

.wrapper span:nth-child(2) {
    padding-left: 8px;
}

.title  {
    padding: 0px 8px ;
}


