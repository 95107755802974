.label {
    color: var(--font-grey);
}

.score {
    font-weight: bold ;
}

.score.red {
    color: var(--red-error);
}

.score.gold {
    color: var(--gold-secondary);
}

.score.green{
    color: var(--green-success);
}

.score.grey {
    color: var(--font-grey);
}