.wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column nowrap; 
    flex: 1 1 80%;;
    align-items: center;
    text-align: center;
    margin: .5em ;
}

.wrapper p {
    font-size: 1em;
    margin: 5px 0; 
}

.bold {
    font-weight: bold;
}




