.btn {
    background: none;
    /* border: 1px solid var(--font-disable); */
    border-radius: 5px;
    padding: 4px;
    position: relative;
    animation: shake-animation 4.72s ease ;

}


.count {
    width: 16px;
    height: 16px;
    color: white;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--gold-secondary);
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10%, -10%);
}



@keyframes shake-animation {
    0% { transform:translate(0,0) }
   1.78571% { transform:translate(5px,0) }
   3.57143% { transform:translate(0,0) }
   5.35714% { transform:translate(5px,0) }
   7.14286% { transform:translate(0,0) }
   8.92857% { transform:translate(5px,0) }
   10.71429% { transform:translate(0,0) }
   100% { transform:translate(0,0) }
 }