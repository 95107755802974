.section {
    padding: 20px 0; 
}

.section h4 {
    color: var(--main-dark-green) ;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.container {
    background-color: #E4E8F0; 
    padding: 15px;
}

.dash-border-wrapper {
    border: dashed 2px var(--primary-neutral);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.dash-border-wrapper .file-input {
    cursor: pointer;
    margin: 0;
    color: var(--primary-neutral);
    font-size: 16px ;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
}

.file-input input {
    display: none;
} 

.file-input .icon {
    color: var(--main-bg-color) ;
}

.file-input::after {
    content: 'Tìm file';
    display: inline-block; 
    background-color: white;
    color: var(--main-bg-color);
    border: 1px solid var(--main-bg-color); 
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-user-select: none;
    text-align: center;
}

.file-input:hover::after {
    color: white;
    background-color: var(--main-bg-color) ;
}

.preview-list {
    margin: 1em 0;
    background-color: white; 
    /* border: 1px solid var(--main-bg-color); */
    padding: 1em;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.note {
    display: flex;
    align-content: center;
    align-items: center;
    flex-flow: row nowrap;
    margin-bottom: 10px;
}
@media(max-width: 576px) {
    .note {
        font-size: 12px;
    }
}

.img-list {
    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-between; */
    justify-content: center;
}

.img-list-mobile {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}

.img-list li {
    flex: 0 32%;
    list-style: none;
    display: flex;
    flex-flow: row nowrap; 
    justify-content: center;
    align-content: center;
    align-items: center;
}

.img-list-mobile li {
    list-style: none;
    display: flex;
    flex-flow: row nowrap; 
    justify-content: center;
    align-content: center;
    align-items: center;
}


.upload-note {
    padding: 4px;
    text-align: center;
    color: var(--font-grey);
    line-height: 1.5em;
}

.btnBar {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.btnBar button {
    font-size: 16px;
    background-color: var(--main-bg-color);
    padding: 6px;
    border-radius: 4px;
    color: white;
}
