.loader {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    /* background: transparent; */
}

.loader img {
    width: 32px;
    height: 32px;
    padding: 0;
}