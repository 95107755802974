.button {
    padding: 10px 20px;
    border-radius: 6px;
    border: transparent;
    font-size: 1.2em;
    background-color: var(--gold-secondary);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.button:hover {
    background-color: var(--main-dark-green);
}

.link {
    text-decoration: none;
    color: white; 
}