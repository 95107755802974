.form {
    padding: 5% 3%;
    background-color: #F6F6F6;
    border-radius: 9px;
    font-size: 0.875rem;
    width: 100%;
}

.form .field-wrapper {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 7px;
    align-content: center;
}

.form label {
    flex-basis: 20%;
    align-self: center;
}

.form input {
    flex: 2 1 90%;
    min-height: 2rem;
    border-color: var(--main-bg-color);
    padding-left: 10px;
}

/* .form input::placeholder {
    padding-left: 10px;
} */

.form button {
    border: none; 
    background-color: none;
    border-radius: 6px;
    padding: 8px 10px; 
    margin: 0 7px;
    font-size: 1rem;
}

.form .action-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 20px 10% 0;
}

.create-btn {
    background-color: var(--main-bg-color);
    color: white;
}

.cancel-btn {
    background-color: white;
}



