.isBuilding {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex: 1 1 auto;
    background-image: url('../../assets/images/isBuilding-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.wrapper {
    border-radius: 20px; 
    background-color: white;
    width: 60%;
    padding: 15px 0; 
}

.iconBg img {
    width: 80%;
    height: 80%;
    max-width: 250px;
    max-height: 250px;
}

.des {
    margin: 10px;
    line-height: 36px;
}