.panelHeading {
    font-size: 16px;
    color: var(--primary-neutral);
    font-weight: 600;
}

.list {
    display: flex;
    flex-flow: column; 
}

@media (min-width: 768px) {
    .panelHeading {
        font-size: 24px;
    }
    .list {
        flex-flow: row wrap; 
    }
}