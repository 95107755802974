.nortification-container {
    display: flex;
    flex-flow: column nowrap;
    align-content: flex-end;
    align-items: flex-end;
    /* padding: .5em 0; */
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 1000;
    /* background-color: white; */
    border-radius: 5px 0 0 5px;
}

.nor-unit {
    width: fit-content;
    /* border: 1px solid #e4570a; */
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 5px 0 0 5px;
    padding: 1px 5px;
    /* background-color: #e4570a; */
    margin: 2px 0;
    align-content: flex-end;
}

.nor-hidden {
    display: none;
}

.nortification-container span {
    margin: 3px;
}

.closeBtn {
    font-size: 10px;
    /* color: #e4570a; */
}

.err-msg {
    font-size: 12px;
    /* color: var(--primary-neutral); */
}

.nor-unit.success {
    border: 1px solid var(--green-success);
}

.nor-unit.error {
    border: 1px solid var(--red-error);
}

.nor-unit.error span {
    color: var(--red-error);
}

.nor-unit.success span {
    color: var(--green-success);
}



