.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav {
    margin: 10px;
}

.table {
    display: flex;
    flex-direction: column;
}

.table .row {
    display: flex;
    flex-flow: row nowrap;
    margin: 2px;
    border: 1px solid var(--grey-bg);
    border-radius: 6px; 
}

.row > div {
    padding: 0 5px;
}

.row div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info p {
    color: var(--font-grey);
    margin: 2px 0;
}

.info .content {
    color: var(--font-primary);
}