.review-table {
    padding: .5em;
}

.row {
    display: flex; 
    flex-flow: row nowrap;
    padding: 4px 8px;
    border-radius: 4px;
    border-bottom: 1px solid var(--grey-bg);
}

.header {
    background-color: var(--main-bg-color);
    color: white;
    border: none;
}

.row div:first-child {
    flex-basis: 20%;
}

.row div:nth-child(2) {
    flex-basis: 60%;
    flex: 2;
}

.row div:last-child {
    flex-basis: 20%;
    flex: 1;
}

.tool-bar {
    display: flex;
    flex-flow: row nowrap;
}

.tool-bar div {
    margin: 0px 2px;
}

@media (min-width: 425px) {
    .row {
        width: 80%;
        margin: 10px auto;
        max-width: calc(1440px * 80%);
    }
}