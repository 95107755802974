.search-container > div {
    margin: 10px 0;
}

.text-field {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid var(--main-bg-color);
    border-radius: 4px; 
    padding: 4px 4px;
    align-items: center;
    align-content: center;
}

.text-field input {
    flex: 2;
    border: none;
    margin: 0 6px;
    padding-left: 2px;
    border-radius: 0;
    height: 20px;
    outline: none; /*to remove border when typing*/
}

.text-field input:visited {
    border: none; 
}

.text-field button {
    display: flex;
}

.text-field button::after {
    content: '';
    position: relative;
    width: 100%;
    height: 20px;
    border-right: 2px solid var(--main-bg-color); 
    left: 6px;
    top: 2px;
}

.options-panel {
    padding-left: 5%;
    color: var(--font-secondary);
}

.options-panel p {
    margin-bottom: 5px;
}

.options-panel label {
    margin-left: 5px;
    color: var(--font-secondary);
}

.options-panel input {
    margin: 0;
    appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    border: 1px solid var(--main-bg-color);
    position: relative;
    top: .5em;
    transform: translateY(-50%);
}

.options-panel input:checked {
    border: 4px solid var(--main-bg-color);
}

.btn-panel {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
