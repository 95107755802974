.container {
  margin: 10px;
}

.container > div {
  margin: 10px 0;
}

.heading {
  font-size: 18px;
  color: var(--main-bg-color);
}

.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}



.info ul {
  margin-left: 10px;
  padding: 5px 0;

}

.info ul li {
  color: var(--font-grey);
  list-style: none;
}

.info .list-info {
  color: var(--font-primary);
  font-weight: 500;
}

.note {
  color: var(--font-grey) ;
  font-size: 12px;
  padding: 20px;
}

.comment {
  margin: 5px 2.5%;
  /* background-color: var(--main-light-green); */
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  border: 1px solid var(--main-bg-color)
}

@media (min-width: 768px) {
  .comment {
    font-size: 14px;
  }
}
