.nort {
    margin: .5em 0;
}

.container {
    padding: 3%;
}

.table-wrapper {
    max-width: 600px;
    margin: 0 auto;
}