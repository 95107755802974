.container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container button {
    margin: 8px;
}

.error-note {
    margin: 15px 0 ;
    color: var(--red-error);
    font-size: 20px;
}

.switcher {
    max-width: 280px;
    width: 280px;
}

@media(max-width: 320px ) {
    .container {
        padding: 0 15px;
    }
}

.dish-title {
    margin: 10px 0 5px;
}

.dish-title span {
    font-weight: 500;
    color: var(--font-primary);
}

.dish-title p {
    color: var(--font-grey);
}