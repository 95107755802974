/* .modal-wrapper {
    width: 100%;
    height: 100%;
    position: absolute; 
    top: 0;
    left: 0;
} */

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center; 
    align-items: center;

}

.err-msg {
    max-width: 300px;
    text-align: center;
    color: #e4570a;
}

.closeBtn {
    width: 70px;
    padding: 3px;
    background: #433e3b;
    color: white;
    border: none;
    margin: 10px;
    text-align: center;
}