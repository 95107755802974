.footer {
    background-color: var(--main-bg-color);
    color: white;
}

.footer > div {
    margin: auto;
    display: flex;
    padding: 2.5rem 11.875rem;
    max-width: 142.5rem;
    justify-content: space-between;
}

@media (max-width: 576px) {
    .footer > div {
        padding: 3% 10%
    }
}