.description {
    display: flex;
    flex-flow: column;
}

.description > div {
    display: flex;
    flex-flow: column;
    margin: 4px 0;
}

.description label,
.id {
    color: var(--main-dark-green);
}

.idNum {
    color: var(--font-grey);
}

.description input {
    height: 24px;
    margin-top: 4px;
    padding-left: 4px;
}

.description textarea {
    border: 1px solid var(--main-bg-color);
    margin: 4px 0;
    border-radius: 4px;
    padding: 4px;
}

.description .icon-panel {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.submit-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px;
}

.submit-btn {
    background: var(--main-bg-color);
    color: white;
    padding: 6px;
    border-radius: 4px;
    font-weight: bold;
}

.submit-panel .submit-btn:disabled {
    color: var(--font-primary);
    background: var(--grey-bg);
    
}

.loading-nort {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}