.field-wrapper {
    display: flex;
    flex-flow: column;
    padding-top: 7px;
    align-content: flex-start;
    z-index: 0;
}

.field-wrapper label {
    color: var(--main-dark-green);
    /* text-transform: capitalize; */
    margin-bottom: 10px;
}

.field-wrapper input {
    padding: 10px 15px ;
}

.field-wrapper input::placeholder {
    text-transform: capitalize;
}