.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container img {
  margin: 10px 0;
  aspect-ratio: auto;
  width: 65%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.nav button {
  margin: 0 8px;
  background: var(--main-bg-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rating > p {
    color: var(--font-grey);
    text-align: center;
}

.rating > div {
    margin: 2px 0;
}

.info {
  max-width: 300px;
  text-align: center;
}

@media (max-width: 320px) {
  .info {
    max-width: 250px;
    word-break: break-all;
  }
}

.info ul li {
  list-style: none;
  margin: 2px 0;
  word-wrap: break-word;
}

.info .label {
  color: var(--font-grey);
}

.nort {
  margin: 10px 0;
}

.score {
    font-weight: bold ;
}

.score.red {
    color: var(--red-error);
}

.score.gold {
    color: var(--gold-secondary);
}

.score.green{
    color: var(--green-success);
}

.score.grey {
    color: var(--font-grey);
}

.submit-nort {
  width: 300px;
  margin: auto;
  word-wrap: break-word;
}
