.chevron {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    border-style: solid;
    border-width: 3px 3px 0 0;
    transform: rotate(45deg);
    transition: .4s ease; 
    border-color: #30BE75;
    cursor: pointer;
}

.active {
    transform: rotate(225deg);
}

.wrapper {
    width: 40px;
    height: 40px;
    background-color: #E6F5ED;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}


