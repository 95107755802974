.container {
  width: 80%;
  max-width: 1440px;
  margin: 1em auto;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  padding: 0 4px;
}

.row > div:first-child {
  flex-basis: 3em;
}

.row > div:last-child {
  flex-basis: 100px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.row > div:nth-child(2) {
  flex: 1;
}

.row > div {
  padding: 8px 0;
}

.header {
  background-color: var(--main-bg-color);
  color: white;
}

.dish-info {
  border-bottom: 1px solid var(--font-disable);
  border-radius: 0;
}

.col {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.col p {
  margin: 0;
  padding: 0;
}

.description > div {
  padding: 0 2px;
}

.description div:nth-child(2) {
  padding: 6px 2px;
}

.col span {
  font-weight: bold;
}

.dish {
  font-size: 18px;
}

.group,
.outlet,
.stt {
  font-size: 14px;
  color: var(--font-secondary);
}

.stt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal {
  padding: 10px;
}

.modal p {
  margin: 8px 0;
  text-align: center;
}

.modal .warning {
  color: var(--red-error);
  font-size: 18px;
}

.modal .note {
  color: var(--font-grey);
  font-size: 14px;
}

.modal .btn-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 8px 12px;
  margin: 6px;
  border-radius: 10px;
}

.button.delete {
  background: var(--red-error);
  color: white;
}

.button.cancel {
  background-color: var(--font-grey);
  color: white;
}

.modal-nort {
  color: var(--blue-info);
  text-align: center;
}
