.backdrop {
    width: 100vw;
    min-height: 100%;
    overflow: auto;
    position: absolute; 
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--font-primary);
    background-size: cover;
    opacity: 0.6;
    z-index: 1;
}