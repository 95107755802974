.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.card {
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 36px;
    min-width: 300px;
}

@media (max-width: 576px) {
    .card {
        padding: 10px 12px;
    }
}
