.top-nav {
    margin-bottom: 5px;
}

.top-nav button {
    background-color: white;
    border: none;
}


.form-section .button-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0 5px;
    margin-top: 20px;
}

.form-section .button-nav button {
    border: none;
    border-radius: 10px;
    color: white;
    margin: 2px;
    padding: 4px 6px;
}

.form-section .button-nav .save-button {
    background-color: var(--main-bg-color);
}

.form-section .button-nav .cancel-button {
    background-color: var(--accent-red);
}