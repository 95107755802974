/* origin styles */

button {
  border: none;
}

.greenBtn {
  background-color: var(--main-light-green);
  padding: 2px 8px;
  border-radius: 10px;
  color: var(--main-bg-color);
  cursor: pointer;
}

.yellowBtn {
  background-color: var(--gold-light);
  padding: 2px 8px;
  border-radius: 10px;
  color: var(--gold-secondary);
  cursor: pointer;
}

.tagNew {
  background-color: var(--accent-red);
  padding: 2px 8px;
  color: white;
  cursor: pointer;
}

.delete {
  color: black;
}

.table p {
  margin: 10px 0;
}

@media (min-width: 769px) {
  .table {
    width: 100%;
    border-radius: 9px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    border-spacing: 0;
    /* margin: 5%; */
  }

  thead {
    background-color: var(--main-bg-color);
    color: white;
  }

  .table tr:first-child {
    border-radius: 9px;
  }

  .table th {
    padding: 3px 5px;
  }

  .table td {
    padding: 3px 5px;
  }

  .top-left {
    border-radius: 9px 0 0 0;
  }

  .top-right {
    border-radius: 0 9px 0 00;
  }
}

/* mobile styles */

@media only screen and (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  .table {
    width: 100%;
    border-radius: 9px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    border-spacing: 0;
    margin-top: 15px;
  }

  .table thead {
    background-color: var(--main-bg-color);
    color: white;
  }

  .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table td {
    padding: 5px 0;
  }

  .table tr {
    border: 1px solid #ccc;
    padding: 5px 10px;
  }

  .table td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: left;
  }

  .table td:nth-child(2) {
    font-weight: 500;
  }

  .table td:before {
    position: absolute;
    left: 5px;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: 500;
  }

  .table td:nth-of-type(1):before {
    content: "STT:";
  }
  .table td:nth-of-type(2):before {
    content: "Món ăn:";
  }
  .table td:nth-of-type(3):before {
    content: "Nhóm:";
  }
  .table td:nth-of-type(4):before {
    content: "Outlet:";
  }
  .table td:nth-of-type(5):before {
    content: "Hình ảnh:";
  }
  .table td:nth-of-type(6):before {
    content: "Công cụ:";
  }
}

.errMsg {
  margin: 15px auto;
  min-height: 50px;
  font-size: 1.5rem;
  color: red; 
}

.green-tag {
  background-color: var(--main-bg-color);
  color: white;
  border-radius: 15%;
  padding: 1px 3px;
}

.grey-tag {
  background-color: var(--grey-bg);
  color: white;
  border-radius: 15%;
  padding: 1px 3px;
}
/* .table tr:first-child {
  border-radius: 9px;
}

.table th {
  padding: 3px 5px;
}

.table td {
    padding: 3px 5px;
}

.top-left {
    border-radius: 9px 0 0 0;
}

.top-right {
    border-radius: 0 9px 0 00;
} */

@media(min-width: 1440px) {
  .table {
    max-width: 1440px;
    width: 70%;
    margin: 5% auto;
  }
}
