.deptForm {
    margin: 3%;
}

@media(min-width: 768px) {
    .deptForm {
        width: 80%;
        margin: 3% auto;
        max-width: 1440px;    
    }
}

.flexTable {
    display: flex;
    flex-flow: column; 
    justify-content: center;
    align-content: center;
    margin: 3%;
    font-size: 14px;
}

@media(min-width: 768px) {
    .flexTable {
        width: 80%;
        margin: 3% auto;
        max-width: 1440px;
    }
}

.theader {
    display: flex;
    flex-flow: row wrap;
    padding: 5px ;
    background-color: var(--main-light-green);
    color: var(--main-dark-green);
}

.theader div {
    flex: 1 1 calc(100%/4);
    justify-content: flex-start;
}

.tableRow {
    display: flex;
    flex-flow: row nowrap; 
    border-bottom: 1px solid #E4E8F0;
    padding: 5px;
}

.tableRow>div {
    flex: 1 1 calc(100%/4);
    justify-content: flex-start;
}

/* .tableRow .mid-col {
    text-align: center;
} */

.tableRow button {
    border-radius: 10px;
    width: 2rem;
    font-size: 12px;
    padding: 2px;
    margin: 0 2px;
    border: none;
    font-size: 10px;
}

.tableRow .deleteBtn {
    background-color: #E8002A;
    color: white;
}

.tableRow .editBtn {
    background-color: #F8F5E6;
    color: #C18D00;
}

.tableRow .panel {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.error-msg {
    width: 70%;
    max-width: 500px;
    margin: 10px auto 0; 
    text-align: center;
    color: red;
}

.loader {
    width: 80px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin: 0 auto;
}

