.container {
  padding: 10px 5%;
}

.container h6 {
  font-size: 22px;
  color: var(--main-dark-green);
  font-weight: 400;
}

.loading {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.note {
  margin: 10px auto;
}

.note p, 
.search-result p {
  text-align: center;
}

.search-result {
  margin: 8px;
}

.search-result span {
  font-weight: bold;
  color: var(--green-success);
}
