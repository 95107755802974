/* .mobileNav li {
    list-style: none;
    margin-bottom: 14px;
} */

.item {
    display: flex;
    flex-direction: column;
    margin: 5px ;
}

.item-name {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.chevron {
    display: flex;
    align-items: center;
}


.wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.title {
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.title span {
    margin: 0 5px 0 12px;
    text-align: right;
}

.wrapper span:last-child {
    display: flex;
    align-items: center;
}

.active {
    color: var(--main-bg-color);
}

.submenu li {
    /* margin-left: 40px;
    margin: 10px; */
    margin: 10px 40px;
    list-style: none;
}

.submenu li a {
    /* color: black; */
    color: var(--font-grey);
    font-size: 14px;
}

.submenu.hide {
    display: none;
}

@media(min-width: 768px) {
    .mobileNav {
        display: none;
    }
}

