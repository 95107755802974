.container {
    margin: .5em 
}

.container > div {
    margin: 1em 0;
}

.approved-list {
    color: var(--font-secondary);
}

.title {
    font-weight: bold;
}

.flex-row {
    display: flex;
    flex-flow: row nowrap;
}

.flex-row div {
    margin: 5px 0;
    flex-basis: calc(100%/3);
    flex: 1;
}

/* .flex-row div:first-child {
    flex-basis: calc(100%/5);
}

.flex-row div:nth-child(2) {
    flex-basis: calc(100%/5*2);
}

.flex-row div:last-child {
    flex-basis: calc(100%/5*2);
} */

.final-approve {
    text-align: center;
    border-top: 1px solid var(--font-disable);
    padding-top: 1em;
}

.final-approve p {
    font-size: 14px;
    margin: 5px
}

.final-approve button {
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.2em;
}

.approve {
    background-color: var(--main-bg-color);
    color: white;
}

.unapprove {
    background-color: var(--font-disable);
    color: var(--font-secondary);
}

.close button {
    background: var(--font-disable);
    border-radius: 5px;
}