
.form {
    padding: 5%;
    background-color: white; 
    height: 100%;
    border-radius: 10px 0 0 10px;
}

.form h3 {
    font-size: 24px;
    font-weight: 600px;
    text-align: center;
    line-height: 28px;
    color: var(--main-dark-green);
    margin: 10px;
}

.form p {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: var(--font-secondary);
}

.form form {
    display: flex;
    flex-flow: column nowrap;
    margin: 10px auto;
}


.form input,
.form label {
    margin: 5px;
    flex: 1 1 auto;
}

.form input {
    padding: 15px 5px ;
    border-radius: 6px;
    border: 1px solid var(--main-bg-color);
}

.form button {
    background: none;
    border: none;
    padding: 15px 5px;
}

.form .login-btn {
    background-color: var(--main-bg-color);
    border-radius: 6px;
    color: white; 
    margin-top: 1.25rem;
}

.form .secondary-btn {
    font-size: 0.75rem;
    text-decoration: underline;
    padding: 0;
    margin: 15px auto 0;
}

.form .secondary-btn a {
    color: var(--font-secondary);
}


.form .secondary-btn a:active, 
.form .secondary-btn a:visited {
    color: var(--font-secondary);
}

.form .error {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 0;
    
}

.error p {
    color: var(--accent-red); 
}

@media (max-width: 992px) {
    .form {
        border-radius: 0px;
    }
}


