.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container img {
    margin: 10px 0;
    aspect-ratio: auto;
    width: 65%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.nav button {
    margin: 0 8px;
    background: var(--main-bg-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}

.info {
    max-width: 300px;
    text-align: center;
    word-break: break-all;
}

@media(max-width: 320px) {
    .info {
        max-width: 280px;
        
    }
}

.info ul li {
    list-style: none;
    margin: 2px 0;
}

.info .label {
    color: var(--font-grey);
}

.nort {
    margin: 10px 0;
}
