.container, .row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.container {
    margin: 10px 0;
    flex-flow: column;
}

.row {
    border: 1px solid var(--grey-bg);
    border-radius: 10px;
    padding: 4px;
    flex-flow: row nowrap;
    margin: 4px 0;
}

.img {
    display: flex;
    flex-direction: column;
    /* flex-basis: 40%; */
    flex: 1 1 50%;
    align-items: center;
    }

.img button {
    max-width: 80px;
}

.img .img-row {
    display: flex;
    flex-direction: row nowrap;
}

.info {
    flex: 1 1 50%;
    padding-left: 4px;
}

.img img {
    min-width: 75px;
    min-height: 75px;
    max-width: 150px;
    max-height: 150px;
    margin: 4px 2px;
    aspect-ratio: 1/1;

}

.info ul{
    margin-bottom: 5px;
}

.info li {
    list-style: none;
    color: var(--font-primary);
}

.info .list-title {
    color: var(--font-grey);
    font-weight: 400;
}

.info li.green {
    color: var(--green-success);
    font-weight: bold;
}

.score {
    font-weight: bold ;
}

.score.red {
    color: var(--red-error);
}

.score.gold {
    color: var(--gold-secondary);
}

.score.green{
    color: var(--green-success);
}

.score.grey {
    color: var(--font-grey);
}

@media (max-width: 425px) {
    .img {
        flex: 1 1 40%;
    }
    .info {
        flex: 1 1 60%;
    }
    .row > div {
        padding: 5px; 
    }

    .info .sm-f {
        font-size: 12px;
    }

    .info li.green {
        font-size: 12px;
    }
}